<template>
  <div>
   
  </div>
</template>

<script>

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: {
  },

  mounted() {},
  created() {},
  data() {
    return {
      bind: {
      },
      formDataConteudo: null,
    };
  },
  watch: {},
  computed:{

  },
  methods: {
    salvar() {
      if (!this.formData.conteudos) {
        this.formData.conteudos = [];
      }

      let intOrdem = this.formDataConteudo.intOrdem;

      if([322,324,325,327].indexOf(this.formDataConteudo.intTipoItemId) != -1){
        let intIndex = this.formData.conteudos.findIndex(
          (c) => c.intTipoItemId == this.formDataConteudo.intTipoItemId
        );

        if(intIndex != -1){
          this.$root.$dialog.warning('O '+this.formDataConteudo.tipo_item.strNome+' já foi inserido')
          return;
        }
      }

      if([322,327].indexOf(this.formDataConteudo.intTipoItemId) != -1){
        intOrdem = 0;
      }
      else if (this.formData.conteudos.length) {
        if (this.formDataConteudo.intTipoInserir == 1) {
          intOrdem--;
        } else {
          intOrdem++;
        }
      } else {
        intOrdem = this.formData.conteudos.length + 1;
      }

      let objItem = {
        intId: this.formDataConteudo.intId,
        intModeloId: this.formDataConteudo.intModeloId,
        intTipoItemId: this.formDataConteudo.intTipoItemId,
        tipo_item: this.formDataConteudo.tipo_item,
        strConteudo: this.formDataConteudo.strConteudo,
        intOrdem: intOrdem,
      };

      if (this.formDataConteudo.intId) {
        let intIndex = this.formData.conteudos.findIndex(
          (c) => c.intId == this.formDataConteudo.intId
        );

        this.formData.conteudos[intIndex] = objItem;
      } else {
        this.formData.conteudos.push(objItem);
      }

      this.formDataConteudo = null;
      this.bind.boolExibirModalConteudo = false;

      this.formData.conteudos.sort(function (a, b) {
        if (a.intOrdem > b.intOrdem) {
          return 1;
        }
        if (a.intOrdem < b.intOrdem) {
          return -1;
        }
        return 0;
      });
    },

  },
};
</script>
