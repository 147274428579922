<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <span class="form-title">Cadastro Geral</span>

        <div class="row">
          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.strNome"
            name="strNome"
            label="Nome"
            rules="required"
            :disabled="!formData.boolAtivo ? true : false"
          />


          <ed-input-tipo
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-0"
            v-model="formData.intTipoListaId"
            name="intTipoListaId"
            label="Tipo de Lista"
            rules="required"
            tipo="1428"
            :disabled="!formData.boolAtivo ? true : false"
            @selected="setTipoLista"
            :info="formData.tipo_lista && formData.tipo_lista.strValor ? formData.tipo_lista.strValor.strObservacao : ''"
          />

          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-0">
            <ed-alert v-if="formData.tipo_lista && formData.tipo_lista.strValor" type="warning">
              <p><b>{{ formData.tipo_lista.strNome}}</b></p>
            {{formData.tipo_lista.strValor.strObservacao}}
          </ed-alert>
          </div>
   

          <ed-input-tipo
            v-if="formData.intTipoListaId == 1430"
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-0"
            v-model="formData.intTipoListaInternaId"
            name="intTipoListaInternaId"
            label="Seleciona uma lista interna"
            rules="required"
            tipo="1431"
            :disabled="!formData.boolAtivo ? true : false"
            @selected="setTipoLista"
            :info="formData.tipo_lista && formData.tipo_lista.strValor ? formData.tipo_lista.strValor.strObservacao : ''"
          />


          <ed-input-switch
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.boolAtivo"
            name="boolAtivo"
            label="Lista ativa?"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdAlert,
  EdInputText,
  EdInputDate,
  EdInputTipo,
  EdInputSwitch,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: {
    EdInputText,
    EdInputDate,
    EdInputTipo,
    EdInputSwitch,
    EdAlert,
  },
  inject: ["bind"],
  mounted() {},
  created() {},
  data() {
    return {};
  },
  watch: {},
  methods: {
    setTipoLista(obj){
      if(obj){
        this.formData.tipo_lista = obj
      }
    }
  },
};
</script>
